import React, { Fragment} from 'react';
import { Normalize } from 'styled-normalize';
import GlobalStyle from 'Assets/styles/global'
import './Assets/styles/index.css'

import Router from './Router';


const App = ()=> {
  return (
    <Fragment >
      <Normalize />
      <GlobalStyle />
      <Router />
    </Fragment>
  );
}

export default App;

import colors from 'Assets/styles/colors';
import styled from 'styled-components'


export const Container = styled.div`
  min-height:100vh;

`;

export const CardContainer = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
`;

export const Illustration = styled.div`
  margin:auto;
  
  svg{
    width: 100%; 
    height: auto;

    
  }
`;


export const BackupArrow = styled.div`
  svg{
    width:3rem;
    height: 3rem;
    fill:${colors.primary.darkBlue};
  }
`;

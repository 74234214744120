import fonts from 'Assets/styles/fonts';
import styled from 'styled-components';


export const Container = styled.div`
  background: #22323F;
  border-radius: 5px;

  width: 124px;
  height: 31px;

  display:grid;
  place-items:center;

  color:white;
  font-family:${fonts.lusitana};
  font-weight:600;

  text-transform:uppercase;
  cursor:pointer;
`;

export const Default = styled.div`
  
`;



import React,{ useEffect, Fragment, FC,CSSProperties } from 'react';
import { } from '@types';
  
import { 
  Container,
  Icon,
} from './SocialIcons.styled';
  
import { ReactComponent as Facebook } from 'Assets/svgs/icons/socialmedia/facebook.svg';
import { ReactComponent as Github} from 'Assets/svgs/icons/socialmedia/github.svg';
import { ReactComponent as Instagram } from 'Assets/svgs/icons/socialmedia/instagram.svg'
import { ReactComponent as LinkedIn} from 'Assets/svgs/icons/socialmedia/linkedin.svg'

interface ISocialIconsProps {
  style?:CSSProperties
}
  
/**
 *
 * @SocialIcons
 *
 */

const SocialIcons:FC<ISocialIconsProps> = (props)=> {
  const { style } = props
  useEffect(()=>{

  },[])

  const socialList = [
    {
      link:`https://www.github.com/Wander95 `,
      icon: <Github />
    },
    {
      link:`https://www.instagram.com/wander_san95`,
      icon: <Instagram />
    },
    {
      link:`https://www.linkedin.com/in/wander-sanchez`,
      icon: <LinkedIn />
    },
    {
      link:`https://www.facebook.com/wander.sanchez.79/`,
      icon: <Facebook />
    }
  ]
  
  return (
  <Fragment>
      <Container >
        {socialList.map((socialNetwork,index)=>
          <Icon 
            target='_blank' 
            key={`${socialNetwork.link}-${index}`}
            href={ socialNetwork.link } style={ style }>
            { socialNetwork.icon }
          </Icon>
        )}
      </Container>
  </Fragment>
  )
}


SocialIcons.defaultProps = {

};
  
  
export default SocialIcons
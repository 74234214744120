import fonts from 'Assets/styles/fonts';
import breakpoints from 'Assets/styles/breakpoints';
import colors from 'Assets/styles/colors';
import styled from 'styled-components'


export const Container = styled.div`
  display:grid;
  place-items:center;
  min-height: 100vh;
  position:relative;
`;

export const GifContainer = styled.div`
  text-align:center;
  max-width:50%;
  img{
    width: 100%;
    height: 100%;
  }
`;

export const Message404 = styled.h1`
  ${fonts.frederick}
  line-height:0;

  color: ${colors.primary.darkBlue};
  @media ${breakpoints.min.tabletM}{
    font-size:10rem;
  }

  @media ${breakpoints.max.tabletM}{
    font-size:5rem;
  }
`;

export const Message = styled.p`
  @media ${breakpoints.min.tabletM}{
      font-size:2rem;
  }
  ${fonts.ubuntuMono}
`;


export const Logo = styled.span`
  a{
    display: block;
    text-decoration:none;
    grid-area:logo;
    ${fonts.frederick}
    ${colors.primary.darkBlue}

    font-style: italic;
    line-height:0.78;
    margin:1rem auto;

    @media ${breakpoints.max.tabletM} {
      margin:1rem auto;
      font-size: 44px;
    }

    @media ${breakpoints.max.mobileL} {
      font-size: 20px;
    } 
  }
  
`;
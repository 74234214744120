import { ReactComponent as Docker } from 'Assets/svgs/icons/skills/backend/docker.svg';
import { ReactComponent as MongoDB } from 'Assets/svgs/icons/skills/backend/mongodb.svg';
import { ReactComponent as Node } from 'Assets/svgs/icons/skills/backend/node.svg';
import { ReactComponent as SQL } from 'Assets/svgs/icons/skills/backend/sql.svg';
import { ReactComponent as SQLServer } from 'Assets/svgs/icons/skills/backend/sqlserver.svg'

const backendIcons = [
  {
    description:'SQL Server',
    icon:SQLServer
  },
  {
    description:'Node JS',
    icon:Node
  },
  {
    description:'Mongoose',
    icon:Node
  },
  {
    description:'Express',
    icon:Node
  },
  {
    description:'MongoDB',
    icon:MongoDB
  },
  {
    description:'Docker',
    icon:Docker
  },
  {
    description:'SQL',
    icon:SQL
  },

]

export default backendIcons
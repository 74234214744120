import { ReactComponent as Insomnia } from 'Assets/svgs/icons/skills/tools/insomnia.svg';
import { ReactComponent as VsCode } from 'Assets/svgs/icons/skills/tools/vscode.svg';
import { ReactComponent as Github } from 'Assets/svgs/icons/skills/tools/github.svg';

const devTools = [
  {
    description:'Insomnia',
    icon:Insomnia
  },
  {
    description:'VsCode',
    icon:VsCode
  },
  {
    description:'Github',
    icon:Github
  }
]

export default devTools
import React,{ Fragment, FC } from 'react';
import { } from '@types';
  
import { 
  Container
} from './Button.styled';
  
import { IButtonContainerProps } from './Button.container'




interface IButtonProps extends IButtonContainerProps{
  
}
  
/**
 *
 * @Button
 *
 */

const Button:FC<IButtonProps> = (props)=> {
  const { name,onClick,style } = props;
  return (
  <Fragment>
    <Container onClick={onClick} style={style}>
      {name}
    </Container>
  </Fragment>
  )
}


Button.defaultProps = {
  
};
  
  
export default Button
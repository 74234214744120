import styled from 'styled-components';
import fonts from 'Assets/styles/fonts';
import colors from 'Assets/styles/colors';

import breakpoints from 'Assets/styles/breakpoints';

export const Container = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  
`;

export const BackgroundIcon = styled.div`
  svg{

  }
`;

export const Title = styled.div`
  font-family: ${fonts.lusitana};
  font-weight: bold;
  font-size: 48px;
  line-height: 62px;
  margin-bottom:2rem;

  @media ${breakpoints.max.mobileL}{
    font-size:35px;
  }
`;

export const Subtitle = styled.div`
  font-family: ${fonts.ubuntuMono};
  font-size: 36px;
  line-height: 36px;

  @media ${breakpoints.max.mobileL}{
    font-size:28px;
  }
`;


export const IconGroupContainer = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:center;

  max-width:45rem;

  margin-bottom:2rem;
`;

export const PlainSkill = styled.div`
  display:grid;
  place-items:center;
  background-color:${colors.primary.darkBlue};
  color:${colors.primary.white};

  min-width:10rem;
  height: 2rem;
  border-radius:5px;

  margin:.5rem;
  padding:.1rem 0;

  @media ${breakpoints.max.mobileL}{
    min-width:10rem;
  }
`;

export const Image = styled.img`
  max-width:2rem;
  max-height:3rem;
`;
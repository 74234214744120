import { ReactComponent as Firebase } from 'Assets/svgs/icons/skills/cloud/firebase.svg';
import { ReactComponent as Security } from 'Assets/svgs/icons/skills/cloud/security.svg';
import { ReactComponent as Linux } from 'Assets/svgs/icons/skills/cloud/linux.svg';

const cloudIcons = [
  {
    description:'Firebase',
    icon:Firebase
  },
  {
    description:'Security',
    icon:Security
  },
  {
    description:'Linux',
    icon:Linux
  },
]


export default cloudIcons
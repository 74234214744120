import colors from 'Assets/styles/colors';
import fonts from 'Assets/styles/fonts';
import breakpoints from 'Assets/styles/breakpoints';
import styled, { css } from 'styled-components'
import Modal from 'styled-react-modal';
import { motion } from 'framer-motion'

const modal = css`
  
`;

export const StyledModal = Modal.styled`
  ${modal}
`

export const ModalWrapper = styled(motion.div)`
  border-radius:5px;
  margin:0 2rem;
  padding:2rem 0;

  max-height:70vh;
  overflow:scroll;
  background-color: white;

  position:relative;
  @media ${breakpoints.min.tablet}{
    padding:2rem 2rem;
  }


  ::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: #FF0000;
  }
  
`;

export const Container = styled.div`
  flex: 1 1 17rem; // Stretching
  margin:1rem auto;
  box-shadow: 
    0px 24px 32px rgba(0, 0, 0, 0.04), 
    0px 16px 24px rgba(0, 0, 0, 0.04), 
    0px 4px 8px rgba(0, 0, 0, 0.04), 
    0px 0px 1px rgba(0, 0, 0, 0.04);

  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;

  max-width:17rem;

  padding:4rem 0 3rem;

  color:${colors.primary.darkBlue};
`;

export const Description = styled.div`
  font-family: ${fonts.ubuntuMono};
  
  font-size: 18px;
  line-height: 23px;
  text-align: center;

  color:${colors.primary.black};

  width: 12rem;

  padding-bottom:6rem;
`;
export const Icon = styled.div`
  
`;

export const Title = styled.div`
  font-family: ${fonts.ubuntuMono};
  font-weight: bold;
  font-size: 24px;
  text-align:center;

  margin:2rem 0 .5rem;
`;

export const CloseIcon = styled.span`
  cursor:pointer;
  position:absolute;
  top:2.5rem;
  right: 1rem;

  @media ${breakpoints.max.mobileS}{
    top:1rem;
    right: .8rem;

  }
  svg{
    width: 2rem;
    height: 2rem;

    
  }
`;

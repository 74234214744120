import React,{ useEffect, Fragment, FC } from 'react';
import { } from '@types';
import { ReactComponent as WebDevelopment } from 'Assets/svgs/icons/skills/web-development.svg';
import { ReactComponent as Cloud } from 'Assets/svgs/icons/skills/cloud.svg'
import { ReactComponent as Design } from 'Assets/svgs/icons/skills/graphic-design.svg'


import { 
  Container,
  CardContainer,
} from './SkillSection.styled';

import { SkillCard } from 'Components';
import { CloudSkills,DesignSkills,DevelopmentSkills } from 'Components/SkillGroups'
import Headline  from 'Layout/Headline';

interface SectionProps {

}
/**
 *
 * @Section
 *
 */

const SkillSection:FC<SectionProps> = ()=> {

  useEffect(()=>{
   
  },[])


  return (
  <Fragment>
    <>
      <Container className='skill-section'>
        <Headline title='Skills'>Skills</Headline>

        <CardContainer>
          <SkillCard 
            icon={ <WebDevelopment /> }
            description='Decent to make some stuff &#9749;.'
            title='Development'>
              
            <DevelopmentSkills />
          </SkillCard>
          <SkillCard 
            icon={ <Cloud /> }
            description='Not enough just yet,but It is a matter of &#x231A;.'
            title='Cloud'>
            <CloudSkills />
          </SkillCard>
          <SkillCard 
            icon={ <Design /> }
            description='Enough to survive &#129409;.'
            title='Design'>
            <DesignSkills />
          </SkillCard>
        </CardContainer>
      </Container>
      </>
  </Fragment>
  )
}


SkillSection.defaultProps = {

};
  
  
export default SkillSection
import React,{ FC, Fragment } from 'react';
import { } from '@types';
  
import { 
  Container,
  GifContainer,
  Message404,
  Message,
  Logo
} from './NotFound.styled';
  
import john from 'Assets/gifs/John.gif'
import { Footer } from 'Layout';
import { Link } from 'react-router-dom';

interface INotFoundProps {

}
  
/**
 *
 * @NotFound
 *
 */

const NotFound:FC<INotFoundProps> = ()=> {


  return (
  <Fragment>
      <Container>
        
        <GifContainer> 
          
          <Message404>
            404
          </Message404>
          <Message>
            <p>
              <strong>Page not found. </strong> 
            </p>  Let's take advantage of the moment and share the pain.
          </Message>
          <Logo> <Link to='/'> Go back to a familiar place </Link> </Logo>
          <img src={ john } alt=""/>
        </GifContainer>
        
      </Container>
      <Footer />
  </Fragment>
  )
}


NotFound.defaultProps = {

};
  
  
export default NotFound
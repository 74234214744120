import React,{ useEffect, FC } from 'react';
import { } from '@types';
import ContactForm from './ContactForm.view';

interface IContactFormProps {

}

const ContactFormContainer:FC<IContactFormProps> = ()=>{
  useEffect(()=>{

  },[])

  const ContactFormProps = {}
  return (
    <ContactForm {...ContactFormProps}/>
  )
}


ContactForm.defaultProps = {

};
  
  
export default ContactFormContainer
import React,{ FC, Fragment } from 'react';
import { } from '@types';
  
import { 
  Container,
  SocialWrapper
} from './Footer.styled';
import { SocialIcons } from 'Components';



interface IFooterProps {

}
  
/**
 *
 * @Footer
 *
 */

const Footer:FC<IFooterProps> = ()=> {


  return (
  <Fragment>
      <Container>
        <SocialWrapper>
          <SocialIcons style={{margin:'0 1rem'}}/>
        </SocialWrapper>
      </Container>
  </Fragment>
  )
}


Footer.defaultProps = {

};
  
  
export default Footer

import colors from 'Assets/styles/colors';
import fonts from 'Assets/styles/fonts';
import breakpoints from 'Assets/styles/breakpoints';
import styled from 'styled-components'


export const HeadlineContainer = styled.div`
display:flex;
justify-content:space-between;


  @media ${breakpoints.min.tablet}{
    margin-top:4rem;
    margin-bottom:8rem;
  }

  @media ${breakpoints.max.tablet}{
    margin-top:4rem;
    margin-bottom:4rem;
  }
  

`;

export const Head = styled.div`
${fonts.frederick}
${colors.primary.darkBlue}

font-style: italic;
line-height:0.78;
font-size: 4rem;
text-align:center;
margin:auto;
@media ${breakpoints.max.tabletM}{
  margin:auto;
  font-size: 3rem;
}
`;
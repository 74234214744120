import React,{ useEffect, Fragment, FC } from 'react';
import { } from '@types';
  
import { 
  Container,
  Items,
  Wrapper,
} from './Navbar.styled';
  
import { Link } from 'react-scroll'
 

interface INavbarProps {
  className?: string;
}
  
/**
 *
 * @Navbar
 *
 */

const Navbar:FC<INavbarProps> = (props)=> {
    const { className } = props;

    useEffect(()=>{

    },[])


  return (
  <Fragment>
      <Container className={className}>
      <Wrapper>
        <Items>
          <Link 
            rel='noopener'
            smooth={true}
            to='skill-section'>skills</Link> 
        </Items>
        <Items style={{padding:'0 2rem'}}>
          <Link 
            rel='noopener'
            to='project-section' 
            smooth={true}>
            projects
          </Link>
          
        </Items>
        <Items>
          <Link 
            rel='noopener'
            to='contact-section' 
            smooth={true}>
            contact me
          </Link>
        </Items>
      </Wrapper>
      </Container>
  </Fragment>
  )
}


Navbar.defaultProps = {

};
  
  
export default Navbar
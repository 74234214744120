import React,{ useEffect, Fragment, FC } from 'react';
import { ReactComponent as Intro  } from 'Assets/svgs/illustrations/intro.svg'
import { } from '@types';
  
import { 
  Container,
  Logo,
  ContentWrapper,
  Illustration,
  Presentation,
  Paragraph,
  SocialIconsContainer
} from './HeroSection.styled';
import Navbar from 'Components/Navbar/Navbar.view';
import { SocialIcons } from 'Components';


interface SectionProps {

}
/**
 *
 * @Section
 *
 */

const HeroSection:FC<SectionProps> = ()=> {

    useEffect(()=>{

    },[])


  return (
  <Fragment>
    <div style={{minHeight:'100vh'}}>
      <Container
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{duration:1,type:"tween"}}
      >
        <Logo>W.S.</Logo>
        <Navbar />
      </Container>

      <ContentWrapper 
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{duration:2,type:"tween"}}
      >
        <Illustration>
          <Intro />
        </Illustration>

        <Presentation>
          <Paragraph>
            Hi there, I am <strong>Wander Sanchez</strong> and,
            I am exactly what you are thinking
            but in a different way. I am a <strong>software developer </strong>  
            with notions of design and a little bit more. 
          </Paragraph>
          <SocialIconsContainer>
            <SocialIcons />
          </SocialIconsContainer>
        </Presentation>
      </ContentWrapper>
    </div>
  </Fragment>
  )
}


HeroSection.defaultProps = {

};
  
  
export default HeroSection
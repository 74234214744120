import colors from 'Assets/styles/colors';
import fonts from 'Assets/styles/fonts';
import breakpoints from 'Assets/styles/breakpoints';
import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled(motion.div)`
  min-height:100vh;

`;

export const CardContainer = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
`;

export const Children = styled.div`
  
`;


export const HeadlineContainer = styled.div`
  display:flex;
  justify-content:space-between;


  @media ${breakpoints.min.tablet}{
    margin-bottom:8rem;
  }


`;

export const Headline = styled.div`
  ${fonts.frederick}
  ${colors.primary.darkBlue}

  font-style: italic;
  line-height:0.78;
  font-size: 4rem;

  @media ${breakpoints.max.tablet}{
    margin:auto;
    font-size: 3rem;
  }
`;

export const BackupArrow = styled.div`
  svg{
    width:3rem;
    height: 3rem;
    fill:${colors.primary.darkBlue};
  }
`;

import firebase from 'firebase/app';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyBU7LRXfXJrLRUuvstC2_rn7dmmUB_t_80",
  authDomain: "crucial-subset-268515.firebaseapp.com",
  databaseURL: "https://crucial-subset-268515.firebaseio.com",
  projectId: "crucial-subset-268515",
  storageBucket: "crucial-subset-268515.appspot.com",
  messagingSenderId: "750957641150",
  appId: "1:750957641150:web:60708087220c9508e8cdef",
  measurementId: "G-GJPPR6H564"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();


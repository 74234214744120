import styled,{ css } from 'styled-components'
import breakpoints from 'Assets/styles/breakpoints';
import Modal from 'styled-react-modal';

const modal = css`
  border-radius:5px;
  margin:0 2rem;
  padding:2rem 1rem;

  max-height:70vh;
  overflow:scroll;
  background-color: white;

  position:relative;
  @media ${breakpoints.min.tablet}{
    max-width:80vh;
  }

  @media ${breakpoints.max.tablet}{
    max-width:100%;
  }


  ::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: #FF0000;
  }
`;

export const StyledModal = Modal.styled`
  ${modal}
`


export const Container = styled.div`
  display:grid;
  place-items:center;
  text-align:center;

  margin:3rem 0;
`;

export const Link = styled.div`
  text-align:center;
  font-size:1.2rem;
  color: #C9C9C9;
  a{
    text-decoration:none;
    color: #C9C9C9;
  }
`;
export const LinkContainer = styled.div`
  span{
    cursor:pointer;
  }
`;

export const Illustration = styled.div`
  width: 100%;
  display:flex;
  justify-content:center;

  img{
    margin:auto;
    
    @media ${breakpoints.min.tablet}{
      width:50%;
    }
    @media ${breakpoints.max.tablet}{
      width:90%;
    }
  }
`;


export const CloseIcon = styled.span`
  cursor:pointer;
  position:absolute;
  top:2.5rem;
  right: 1rem;

  @media ${breakpoints.max.mobileS}{
    top:1rem;
    right: .8rem;

  }
  svg{
    width: 2rem;
    height: 2rem;

    
  }
`;

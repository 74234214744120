import React,{ useEffect, Fragment, FC } from 'react';
import { } from '@types';
  
import { 
  Container,
  Description,
  DescriptionContainer,
  Icon,
  IconContainer
} from './SkillIcon.styled';
  


interface ISkillIconProps {
  description?:string;
}
  
/**
 *
 * @SkillIcon
 *
 */

const SkillIcon:FC<ISkillIconProps> = (props)=> {
  const { description,children } = props;

    useEffect(()=>{

    },[])


  return (
  <Fragment>
      <Container>
        <IconContainer>
          <Icon> {children} </Icon>
        </IconContainer>
        <DescriptionContainer>
          <Description> { description } </Description>
        </DescriptionContainer>
      </Container>
  </Fragment>
  )
}


SkillIcon.defaultProps = {

};
  
  
export default SkillIcon
import React,{ useEffect, useState, Fragment, FC,ReactElement,CSSProperties } from 'react';
import { } from '@types';
  
import { 
  Container,
  Description,
  Icon,
  Title,
  StyledModal,
  CloseIcon,
  ModalWrapper
} from './SkillCard.styled';
import Button from 'Components/Button';
import { ReactComponent as Close } from 'Assets/svgs/icons/close.svg'
  
import { AnimatePresence } from 'framer-motion'
interface ISkillCardProps {
  description?:string;
  icon?:ReactElement;
  title?:string;
  style?:CSSProperties
}
  
/**
 *
 * @SkillCard
 *
 */

const SkillCard:FC<ISkillCardProps> = (props)=> {
  const { description,icon,title,style,children } = props;
  
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = ()=> {
    setIsOpen(!isOpen)
  }

  useEffect(()=>{

  },[])


  return (
  <Fragment>
      <Container style={ style }>
        <Icon> { icon } </Icon>
        <Title > { title } </Title>
        <Description> { description } </Description>
        <Button onClick={ toggleModal }/>
      </Container>

      {/* Modal */}
      <StyledModal
        isOpen={isOpen}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}>
          <AnimatePresence >
            {isOpen && (
              <ModalWrapper
                initial={{scale:0}}
                animate={{scale:1}}
              >
                { children }
                <CloseIcon onClick={toggleModal}>
                  <Close />
                </CloseIcon>
                <div style={{display:'grid',placeItems:'center'}}>
                  <Button 
                  name='Close'
                  onClick={toggleModal} 
                  style={{alignSelf:'center',backgroundColor:'#7A2A2C'}}>Close</Button>
                </div>
                </ModalWrapper>
            )}
          </AnimatePresence>
          
      </StyledModal>
  </Fragment>
  )
}


SkillCard.defaultProps = {
  description:'no data'
};
  
  
export default SkillCard

import colors from 'Assets/styles/colors';
import fonts from 'Assets/styles/fonts';
import breakpoints from 'Assets/styles/breakpoints';
import styled from 'styled-components'
import { motion } from 'framer-motion'


export const Container = styled(motion.div)`
  display:grid;
  grid-template-columns:repeat(12,1fr);

  @media ${breakpoints.max.tabletM} {
    grid-template-columns:100%;
    grid-template-rows:1fr 1fr;
    grid-template-areas:
    'navbar navbar'
    'logo logo';
  }

  @media ${breakpoints.min.tabletM} {
    grid-template-columns:1vw;
    grid-template-rows:1fr 1fr;
    grid-template-areas:
    'logo logo . . . . . . . . navbar navbar';
  }

  
`;

export const Children = styled.div`
  
`;

export const Logo = styled.span`
  grid-area:logo;
  ${fonts.frederick}
  ${colors.primary.darkBlue}

  font-style: italic;
  line-height:0.78;
  font-size: 144px;

  @media ${breakpoints.max.tabletM} {
    margin:1rem auto;
    font-size: 100px;
  }

  @media ${breakpoints.max.mobileL} {
    font-size: 80px;
  } 
`;


export const ContentWrapper = styled(motion.div)`

  @media ${breakpoints.min.tabletM} {
    display:grid;
    gap:1rem;
    grid-template-columns:2fr 2fr;
    grid-template-areas:
      'presentation illustration'
    ;
  }

`;

export const Illustration = styled.div`
  
  grid-area:illustration;
  place-items:center;
  
  @media ${breakpoints.max.tabletM} {
      
    width: 90%;
    margin:auto;
    padding-bottom:5rem;
  }
    

  @media ${breakpoints.max.mobileL} {

    padding-bottom:5rem;
  }


  
  svg{
    height: auto;
    width: 100%;
 

  }
`;

export const Presentation = styled.div`
  grid-area:presentation;
  
  @media ${breakpoints.min.tabletM} {
    padding-right:3.5rem;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
  }
`;

export const Paragraph = styled.div`
  margin-bottom:2rem;
  font-size:2rem;
  font-weight:normal;
  
  @media(max-width: 1080px){
    font-size:1.8rem;
  }

  @media ${breakpoints.max.tabletM} {
    margin:0 auto 2rem auto;
    font-size:1.2rem;
    margin-top:1rem;
    text-align:center;

    padding:0 .5rem;
  }

  
`;

export const  SocialIconsContainer = styled.div`

  @media ${breakpoints.min.tabletM} {
    margin-right:2rem;
  }

  @media ${breakpoints.max.tabletM} {
    display:grid;
    place-items:center;
    margin:auto;
    width: 80%;

    svg{
      margin:0 1rem;
    }
  }
  
`;
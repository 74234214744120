const colors =  {
  primary:{
    black:'#000C14',
    darkBlue:'#22323F', 
    wine:'#7A2A2C',
    rose:'#D17076',
    creamy:'#E2DAD4',
    gray:'#F2F2F2',
    white:'#FFFFFF'
  }
}

export default colors
import React,{ FC , Fragment } from 'react';
import {  } from '@types';

import { 
  Container,
} from './Home.styled';

import { 
  ContactSection, 
  Footer, 
  HeroSection,
  ProjectSection, 
  SkillSection,
  CreditSection, 
} from 'Layout';

import "animate.css/animate.min.css";

interface IHomeProps {

}

/**
 *
 * @Home
 *
 */


const Home:FC<IHomeProps> = ()=> {
  return (
    <Fragment>
        <Container
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{duration:1,type:"tween"}}
        >
          <HeroSection />
          <SkillSection />
          <ProjectSection />
          <ContactSection />
          <CreditSection />
        </Container>
        <Footer />
    </Fragment>
  )
}


Home.defaultProps = {

};
  
  
export default Home
import { ReactComponent as CSS } from 'Assets/svgs/icons/skills/frontend/css.svg';
import { ReactComponent as HTML } from 'Assets/svgs/icons/skills/frontend/html.svg';
import { ReactComponent as JS } from 'Assets/svgs/icons/skills/frontend/javascript.svg';
import { ReactComponent as Flutter } from 'Assets/svgs/icons/skills/frontend/flutter.svg';
import { ReactComponent as Handlebars } from 'Assets/svgs/icons/skills/frontend/handlebars.svg';
import { ReactComponent as Jest } from 'Assets/svgs/icons/skills/frontend/jest.svg';
import { ReactComponent as ReactI } from 'Assets/svgs/icons/skills/frontend/react.svg';
import { ReactComponent as Redux } from 'Assets/svgs/icons/skills/frontend/redux.svg';
import { ReactComponent as Typescript } from 'Assets/svgs/icons/skills/frontend/typescript.svg';
import { ReactComponent as Tailwind } from 'Assets/svgs/icons/skills/frontend/tailwind.svg';


const frontendIcons = [
  {
    description:'CSS',
    icon:CSS
  },
  {
    description:'HTML',
    icon:HTML
  },
  {
    description:'Java Script',
    icon:JS
  },
  {
    description:'Flutter',
    icon:Flutter
  },
  {
    description:'Handlebars',
    icon:Handlebars
  },
  {
    description:'Jest',
    icon:Jest
  },
  {
    description:'React',
    icon:ReactI
  },
  {
    description:'Redux',
    icon:Redux
  },
  {
    description:'Typescript',
    icon:Typescript
  },
  {
    description:'Tailwind',
    icon:Tailwind
  }
]

export default frontendIcons
import colors from 'Assets/styles/colors';
import fonts from 'Assets/styles/fonts';
import breakpoints from 'Assets/styles/breakpoints';
import styled from 'styled-components'

export const Container = styled.div`

  margin-bottom:5rem;
`;

export const Children = styled.div`
  
`;



export const BackupArrow = styled.div`
  svg{
    width:3rem;
    height: 3rem;
    fill:${colors.primary.darkBlue};
  }
`;

export const ContentWrapper = styled.div`
  display:grid;
  grid-template-columns:50% 50%;
  grid-template-areas:
    'form paragraph'
  ;

  @media ${breakpoints.max.tablet}{
    grid-template-columns:50% 50%;
    grid-template-rows:1fr;
    grid-template-areas:
      'paragraph paragraph'
      'form form'
    ;


  }

`;

export const Paragraph = styled.p`

  grid-area:paragraph;
  font-family: ${fonts.ubuntuMono};
  font-weight: normal;
  font-size: 2em;
  align-items: center;

  position:relative;
  top:1rem;

  @media ${breakpoints.max.tablet}{
    font-size: 1.8em;
    text-align:center;
  }

  @media ${breakpoints.max.mobileL}{
    font-size: 1.2em;
    padding:0 1rem;
  }

  
`;


export const IconContainer  = styled.span`
  position:absolute;
    bottom: 1rem;
    left: 0;
    @media ${breakpoints.max.tabletM}{
      display: none;
    }

  svg{
    
    @media ${breakpoints.min.tabletM}{
      height:10rem;
      width: 10rem;
    }
  }
`;
import React,{ useEffect, Fragment, FC } from 'react';
import { } from '@types';
  
import { 
  Container,
  ContentWrapper,
  Paragraph,
  IconContainer
} from './ContactSection.styled';

import { ContactForm } from 'Components'
import Headline  from 'Layout/Headline';
import { ReactComponent as Axe } from 'Assets/svgs/icons/axe.svg'

interface SectionProps {

}
/**
 *
 * @Section
 *
 */

const ContactSection:FC<SectionProps> = ()=> {

    useEffect(()=>{

    },[])


  return (
  <Fragment>
      <Container className='contact-section'>
        <Headline title='Like what you see?' />
        <ContentWrapper>
          <ContactForm />

          <Paragraph>
            <strong> " </strong>
            <cite>
            Give me six hours to chop down a tree and 
            I will spend the first four sharpening the ax.
            </cite>
            <strong> " </strong> 
            <br/>
            <br/>
            <strong> Abraham Lincoln </strong>
            <IconContainer>
              <Axe />
            </IconContainer>
          </Paragraph>
        </ContentWrapper>
      </Container>
  </Fragment>
  )
}


ContactSection.defaultProps = {

};
  
  
export default ContactSection
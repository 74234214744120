import styled from 'styled-components'


export const Container = styled.div`
  background-color: #F2F2F2;
  padding:2rem 0;

  display:flex;
  justify-content:center;

  width: 100%;

`;

export const SocialWrapper = styled.div`
  
`;

export const Children = styled.div`
  
`;

import colors from 'Assets/styles/colors';
import fonts from 'Assets/styles/fonts';
import breakpoints from 'Assets/styles/breakpoints';
import styled from 'styled-components'


export const Container = styled.div`
  display:flex;
  
  
  grid-area:navbar;

  @media ${breakpoints.min.tablet} {
    justify-content:flex-end;
  }

  @media ${breakpoints.max.tabletM} {
    margin:auto;
  }

  @media ${breakpoints.max.mobileS}{
    width: 90%; 
    margin:auto;
  }

  
`;

export const Wrapper = styled.div`
  padding:1rem;
  border-radius:6px;

  display:flex;
  justify-content:space-evenly;
  
  align-self:flex-end;
  
  max-width: 25rem;
  min-width:15rem;

  @media ${breakpoints.min.tablet} {
    width: 25rem;
  }

 
`;

export const Items = styled.div`
  color:${colors.primary.darkBlue};
  font-family:${fonts.lusitana};
  
  text-transform:uppercase;
  font-weight:700;

  @media ${breakpoints.min.tabletM}{
    font-size:1.5rem;
  }

  @media ${breakpoints.max.mobileS}{
    font-size:.8rem;
  }

  :hover{
    text-decoration:underline;
    transition:.5s;
  }

  cursor:pointer;
`;

import React,{ useEffect, Fragment, FC } from 'react';
import { } from '@types';
  
import { 
  Container,
  Illustration,
} from './ProjectSection.styled';

import { ReactComponent as InProgress } from 'Assets/svgs/illustrations/inprogress.svg'
import Headline  from 'Layout/Headline';

interface SectionProps {

}
/**
 *
 * @Section
 *
 */

const ProjectSection:FC<SectionProps> = ()=> {

    useEffect(()=>{

    },[])


  return (
  <Fragment>
      <Container className='project-section'>
        <Headline title='Projects'/>
        <Illustration>
          <InProgress />
        </Illustration>
      </Container>
  </Fragment>
  )
}


ProjectSection.defaultProps = {

};
  
  
export default ProjectSection
import React,{ FC } from 'react'
import { Head,HeadlineContainer} from './Headline.styled';

interface IHeadline {
  title?:string;
}

const Headline:FC<IHeadline> = (props)=> {
  const { title } = props;
  return (
    <HeadlineContainer>
      <Head> { title } </Head>
    </HeadlineContainer>
  )
}

export default Headline

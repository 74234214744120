import colors from 'Assets/styles/colors';
import fonts from 'Assets/styles/fonts';
import breakpoints from 'Assets/styles/breakpoints';
import styled from 'styled-components'

export const Container = styled.div`
  
`;

export const FormContainer = styled.div`
  grid-area:form;
  padding:0 1rem;
  position:relative;

  @media ${breakpoints.max.tablet}{
    margin:auto;
    width: 80%;
  }

  @media ${breakpoints.max.mobileL}{
   
    width: 90%;
  }
`;

export const InputContainer = styled.div`
  margin:2rem 0;
  padding-right:6rem;

  @media ${breakpoints.max.tablet}{
    
    padding-right:0;
  }
`;

export const Input = styled.input`
  padding:.8rem .5rem;
  border: 0.5px solid #22323F;
  box-shadow: 
    0px 16px 24px rgba(0, 0, 0, 0.06), 
    0px 2px 6px rgba(0, 0, 0, 0.04), 
    0px 0px 1px rgba(0, 0, 0, 0.04);

  width: 100%;

  @media ${breakpoints.max.mobileL}{
    max-width:95%;
  }
`;

export const TextArea = styled.textarea`
  padding:.8rem .5rem;
  border: 0.5px solid #22323F;
  box-shadow: 
    0px 16px 24px rgba(0, 0, 0, 0.06), 
    0px 2px 6px rgba(0, 0, 0, 0.04), 
    0px 0px 1px rgba(0, 0, 0, 0.04);

  min-width:100%;
  min-height:5rem;
  max-height:5rem;

  @media ${breakpoints.max.mobileL}{
    max-width:95%;
    min-width:94.5%;
  }
`;

export const Label = styled.label`
  font-family: ${fonts.ubuntuMono};
  font-weight: bold;
  font-size: 30px;

  display: block;

  @media ${breakpoints.max.mobileL}{
    font-size: 1.2em;
  }
`;

export const SubmitButton = styled.span`
  position:relative;
  display:grid;
  place-items:center;

  background-color:${colors.primary.darkBlue};
  color: ${colors.primary.white};
  text-transform:uppercase;

  letter-spacing:1px;

  width: 90.55px;
  height: 28.39px;

  border-radius:5px;

  cursor:pointer;
`;

export const ErrorMessage = styled.span`
  color:red;
`;

export const LoaderContainer = styled.div`
  position:absolute;
  bottom: 0;
  right: -55%;
  
  
`;


type LoaderProps = {
  success?:boolean
}

export const LoaderFormMessage = styled.div`
  position:absolute;
  font-weight:600;
  right: 
  ${({success}:LoaderProps)=>
    success?'-275%':'-275%'
  };
  
  width: 15rem;

  @media ${breakpoints.max.mobileS}{
    width: 10rem;

  right: 
  ${({success}:LoaderProps)=>
    success?'-155%':'-215%'
  };
  }

  color:
    ${({success}:LoaderProps)=>
      success?'green':'red'
    }
  ;
`;
import React from 'react'
import SkillIcon from 'Components/SkillIcon';
import {
  Container, 
  IconGroupContainer,
  Subtitle,
  Title,
  PlainSkill 
} from './CloudSkills.styled';

import cloudIcons from 'Constants/SkillIconGroups/cloud.group';

const CloudSkills = ()=> {
  return (
    <Container>
    <Title>Cloud</Title>
      <IconGroupContainer>
        {cloudIcons.map((cloud,index)=>
          <SkillIcon 
            key={`${cloud.description} ${index}`}
            description={cloud.description}>
            {<cloud.icon />}
          </SkillIcon>
        )}
      </IconGroupContainer>
      <Subtitle>Concepts</Subtitle>
      <IconGroupContainer>
        <PlainSkill>Cloud Functions</PlainSkill>
        <PlainSkill>SSH</PlainSkill>
        <PlainSkill>Microservices</PlainSkill>
      </IconGroupContainer>
  </Container>
  )
}

export default CloudSkills

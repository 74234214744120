import React,{ useEffect,useState, Fragment, FC } from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

import { } from '@types';
  
import { 
  FormContainer,
  Input,
  InputContainer,
  Label,
  TextArea,
  SubmitButton,
  ErrorMessage,
  LoaderContainer,
  LoaderFormMessage
} from './ContactForm.styled';

import axios from 'axios';
import { Formik,FormikHelpers } from 'formik';
import * as Yup from 'yup';


interface IContactFormProps {

}

interface IContactInfo {
  fullName: string;
  email: string;
  message: string;
}
  
/**
 *
 * @ContactForm
 *
 */

const ContactForm:FC<IContactFormProps> = ()=> {
    const [ formMessage,setFormMessage ] = useState('');
    const [ submitted,setSubmitted ] = useState(false);
    const [ success,setSuccess ] = useState(false);

    useEffect(()=>{

    },[])

    const initialValues:IContactInfo = {
      fullName:'',
      email:'',
      message:''
    }
  
    const onSubmit = async (
      values: IContactInfo, 
      formikHelpers: FormikHelpers<IContactInfo>
    )=>{
      console.log(values)
      setSubmitted(true);
      try {
        await axios({
          url:'https://formspree.io/f/xvovrplg',
          method:'POST',
          data:{
            fullName:values.fullName,
            email:values.email,
            message:values.message
          }
        })
        formikHelpers.resetForm();
        setFormMessage('Message Sent');
        setSuccess(true);
        setSubmitted(true);
      } catch (error) {
        setFormMessage('There was an error sending the message');
        setSuccess(false);
        setSubmitted(true)
      }
    }
  
    const validationSchema = Yup.object().shape({
      fullName:Yup.string()
      .min(1,'Provide a valid name')
      .required('Required'),
      email:Yup.string()
      .email('Invalid email')
      .required('Required'),
      message:Yup.string()
      .required('Required')
    })

  return (
  <Fragment>
      <FormContainer>
        <Formik 
          initialValues={initialValues} 
          onSubmit={ onSubmit}
          validationSchema={ validationSchema }>
            {(props)=>{
              const { email,fullName,message }= props.touched;
              if( email || fullName || message ){
                setFormMessage('')
              }
              
              return (
                <Fragment>
                  <InputContainer>
                    <Label>Full Name</Label>
                    <Input 
                      name='fullName' 
                      value={ props.values.fullName } 
                      disabled={ props.isSubmitting }
                      onChange={ props.handleChange }
                      onBlur={props.handleBlur}/>
                      <ErrorMessage>
                        { props.touched.fullName
                            && props.errors.fullName }
                      </ErrorMessage>
                  </InputContainer>

                  <InputContainer>
                    <Label>Email</Label>
                    <Input
                    name='email' 
                    value={ props.values.email } 
                    disabled={ props.isSubmitting }
                    onChange={ props.handleChange }
                    onBlur={props.handleBlur}/>
                    <ErrorMessage>
                      { props.touched.email 
                        && props.errors.email }
                    </ErrorMessage>
  
                  </InputContainer>

                  <InputContainer>
                    <Label>Message</Label>
                    <TextArea
                      name='message' 
                      disabled={ props.isSubmitting }
                      value={ props.values.message } 
                      onChange={ props.handleChange }
                      onBlur={props.handleBlur}/>
                      <ErrorMessage>
                        {props.touched.message 
                          && props.errors.message }
                      </ErrorMessage>
                  </InputContainer>
                  <SubmitButton onClick={ props.submitForm }>
                      Send
                    {
                      props.isSubmitting && 
                      <LoaderContainer>
                        <Loader 
                          type='ThreeDots' 
                          color="#7A2A2C" 
                          height={40} 
                          width={40}
                        />
                      </LoaderContainer>
                    }

                    {
                      submitted && 
                      <LoaderFormMessage {...{success: success ? true : false}}>
                        { formMessage }
                      </LoaderFormMessage>
                    }
                  </SubmitButton>

                </Fragment>
              )
            }}
        </Formik>
      </FormContainer>

  </Fragment>
  )
}


ContactForm.defaultProps = {

};
  
  
export default ContactForm
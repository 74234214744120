import React,{ useEffect, FC } from 'react';
import { } from '@types';
  
import SkillIcon from './SkillIcon.view';
export interface ISkillIconProps {
  description?:string;
}

const SkillIconContainer:FC<ISkillIconProps> = (props)=>{
  useEffect(()=>{

  },[])

  const SkillIconProps = {...props}
  return (
    <SkillIcon {...SkillIconProps}/>
  )
}


SkillIcon.defaultProps = {

};
  
  
export default SkillIconContainer
import React from 'react'
import SkillIcon from 'Components/SkillIcon';
import {
  Container, 
  IconGroupContainer,
  Subtitle,
  Title,
  PlainSkill,

} from './DesignSkills.styled';

import designIcons from 'Constants/SkillIconGroups/design.tools.group';

const DesignSkills = ()=> {
  return (
    <Container>
    <Title>Design</Title>
    <Subtitle>Tools</Subtitle>
      <IconGroupContainer>
        {designIcons.map((design,index)=>
          <SkillIcon 
            key={`${design.description}-${index}`}
            description={design.description}>
            {<design.icon />}
          </SkillIcon>
        )}
        
      </IconGroupContainer>

      <Subtitle>Concepts</Subtitle>
      <IconGroupContainer>
        <PlainSkill>User Interface</PlainSkill>
        <PlainSkill>Responsive Design</PlainSkill>
      </IconGroupContainer>

  </Container>
  )
}

export default DesignSkills

import SkillIcon from 'Components/SkillIcon';
import React,{ FC } from 'react'
import { 
  Container, 
  IconGroupContainer,
  Subtitle,
  Title,
  PlainSkill,
  Image 
} from './DevelopmentSkills.styled';

import backendIcons from 'Constants/SkillIconGroups/backend.group';
import frontendIcons from 'Constants/SkillIconGroups/frontend.group';
import toolsDev from 'Constants/SkillIconGroups/dev.tools.group';

import  Styled from 'Assets/svgs/icons/skills/frontend/styled-components.png';

interface IDevelopmentSkills {

}

const DevelopmentSkill: FC<IDevelopmentSkills> = ()=> {
  return (
    <Container>
      <Title>Development</Title>
      <Subtitle>FrontEnd</Subtitle>
      <IconGroupContainer>
        {frontendIcons.map((frontend,index)=>
          <SkillIcon 
            key={`${frontend.description}-${index}`}  
            description={frontend.description}>
            {<frontend.icon />}
          </SkillIcon>
        )}
        <SkillIcon description='Styled Components'>
          <Image  src={Styled} alt="" />
        </SkillIcon>
      </IconGroupContainer>

      <Subtitle>BackEnd</Subtitle>
      <IconGroupContainer>
        {backendIcons.map((backend,index)=>
          <SkillIcon 
            key={`${backend.description}-${index}`}          
            description={backend.description}>
            {<backend.icon/>}
          </SkillIcon>
        )}
      </IconGroupContainer>

      <Subtitle>Tools</Subtitle>
      <IconGroupContainer>
        {toolsDev.map((tools,index)=>
          <SkillIcon 
            key={`${tools.description}-${index}`}      
            description={tools.description}>
          {<tools.icon/>}
        </SkillIcon>
        )}
      </IconGroupContainer>
      
      <Subtitle>Concepts</Subtitle>
      <IconGroupContainer>
        <PlainSkill>UML</PlainSkill>
        <PlainSkill>Software Lifecycle</PlainSkill>
        <PlainSkill>Design Patterns</PlainSkill>
        <PlainSkill>CI / CD</PlainSkill>
        <PlainSkill>REST API</PlainSkill>
        <PlainSkill>SOLID</PlainSkill>
      </IconGroupContainer>
    </Container>
  )
}

export default DevelopmentSkill

import React,{ useEffect, FC,CSSProperties } from 'react';
import { } from '@types';
import Button from './Button.view';

export interface IButtonContainerProps {
  name?:string;
  onClick?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined,
  style?:CSSProperties
}

const ButtonContainer:FC<IButtonContainerProps> = (props)=>{
  //const { name,onClick } = props;
  //const [] = useState();
  useEffect(()=>{

  },[])

  const ButtonProps = { ...props }
  return (
    <Button {...ButtonProps}/>
  )
}


Button.defaultProps = {
  name:'Detail'
};
  
  
export default ButtonContainer
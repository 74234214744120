import React from 'react'
import { Home,NotFound } from 'Page'
import { Route,Switch, BrowserRouter } from 'react-router-dom';
import { ModalProvider } from 'styled-react-modal'

function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/'>
          <ModalProvider>
            <Home />
          </ModalProvider>
        </Route>
        <Route component={ NotFound }>
          
        </Route>
      </Switch>
    </BrowserRouter> 
  )
}

export default Router

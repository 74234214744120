import { css } from "styled-components";

const fonts =  {
  poppins: css`
    font-family: 'Poppins', sans-serif;
  `,
  frederick:css`
    font-family: 'Fredericka the Great', cursive;
  `,
  ubuntuMono:css`
    font-family: 'Ubuntu Mono', monospace;
  `,
  lusitana:css`
    font-family: 'Lusitana', serif;
  `,
  sizes:{
    h1:css`
      font-weight:600;
      font-size:1.9rem;
      letter-spacing:2px;
    `,
    h3:css`
      font-weight: 500;
      font-size: 1.5em;
      letter-spacing: -0.005em;
    `
  }
}

export default fonts
import React,{ FC, Fragment, useState } from 'react';
import { } from '@types';
  
import { 
  Container,
  Link,
  LinkContainer,
  StyledModal,
  Illustration,
  CloseIcon
} from './CreditSection.styled';
  
import  Working  from 'Assets/images/working.jpg'
import  construction  from 'Assets/images/construction.jpg'

import { ReactComponent as Close } from 'Assets/svgs/icons/close.svg'

interface ICreditSectionProps {

}
  
/**
 *
 * @CreditSection
 *
 */

const CreditSection:FC<ICreditSectionProps> = ()=> {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = ()=> {
    setIsOpen(!isOpen)
  }

  return (
  <Fragment>
      <StyledModal
        isOpen={isOpen}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}>
          <CloseIcon onClick={toggleModal}>
              <Close />
            </CloseIcon>
          <LinkContainer>
            <Illustration>
              <img src={ Working } alt=""/>
            </Illustration>
            <Link>
              <a 
              href='https://www.freepik.com/free-vector/work-time-concept-illustration_7117898.htm#page=1&query=ilustration&position=7'> Designed by slidesgo / Freepik </a>
            </Link>

            <Illustration>
              <img src={ construction } alt=""/>
            </Illustration>
            <Link>
              <a 
              href="https://www.freepik.com/free-vector/construction-concept-illustration_8960493.htm#page=1&query=construction&position=16">Designed by stories / Freepik</a>
            </Link>
          </LinkContainer>
      </StyledModal>
      <Container>
        <LinkContainer>
          
          <Link> 
            Advisory: <strong> <a href="https://www.instagram.com/Ishbert/">Ithsbert</a> </strong>
          </Link>
          <Link> 
            Illustrations: <strong> <span onClick={toggleModal}>Open to see</span> </strong>
          </Link>
          <Link> 
            Icons: <strong><a href="https://www.svgrepo.com/">svgrepo.com</a> </strong>
          </Link>
        </LinkContainer>
      </Container>
  </Fragment>
  )
}


CreditSection.defaultProps = {

};
  
  
export default CreditSection
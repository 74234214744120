import React,{ useEffect, FC } from 'react';
import { } from '@types';
  
import Navbar from './Navbar.view';

interface INavbarProps {

}

const NavbarContainer:FC<INavbarProps> = ()=>{
  useEffect(()=>{

  },[])

  const NavbarProps = {}
  return (
    <Navbar {...NavbarProps}/>
  )
}


Navbar.defaultProps = {

};
  
  
export default NavbarContainer
import styled from 'styled-components'
import breakpoints from 'Assets/styles/breakpoints';
import { motion } from 'framer-motion'

export const Container = styled(motion.div)`
  max-width: 1140px;
  margin:5rem auto 0;

  @media (max-width: 1140px) {
    padding:0 2rem;
  }
  
  @media ${breakpoints.min.tablet} {
    padding:0 2rem;
  }
  
  @media ${breakpoints.max.mobileL} {
    margin:0 auto 0;
    padding:0 0;
  }
`;

export const IntroContainer = styled.div`
  position:fixed;
  top:0;
  z-index:5;
  height: 100vh;
  width: 100vw;

  background-color:black;

  display:grid;
  place-items:center;
`;

export const IntroText = styled(motion.span)`
  color: white;
  font-size:2rem;
`;

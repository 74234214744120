import styled from 'styled-components'


export const Container = styled.div`
  display:flex;
  justify-content:space-between;


  @media (max-width: 768px) {
    max-width: 20rem;
    
  }
`;

export const Children = styled.div`
  
`;

export const Icon = styled.a`
 

  svg{
    width: 2.5rem;
    height: 2.5rem;

    @media (max-width: 768px) {
      width: 2.2rem;
      height: 2.2rem;
    }
  }
`;
import { ReactComponent as Figma } from 'Assets/svgs/icons/skills/tools/figma.svg';
import { ReactComponent as Inskcape } from 'Assets/svgs/icons/skills/tools/inskcape.svg';
import { ReactComponent as Davinci } from 'Assets/svgs/icons/skills/tools/davinci-resolve.svg';
import { ReactComponent as Gimp } from 'Assets/svgs/icons/skills/tools/gimp.svg';

const designTools = [
  {
    description:'Figma',
    icon:Figma
  },
  {
    description:'Inskcape',
    icon:Inskcape
  },
  {
    description:'Davinci Resolve',
    icon:Davinci
  },
  {
    description:'GIMP',
    icon:Gimp
  },
]

export default designTools
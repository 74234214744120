import styled from 'styled-components'
import colors from 'Assets/styles/colors';
import fonts from 'Assets/styles/fonts';
import breakpoints from 'Assets/styles/breakpoints';

export const Container = styled.div`
  display:grid;
  grid-template-columns:35% 55%;
  background-color:${colors.primary.darkBlue};
  
  min-width:10rem;
  height: 3rem;
  border-radius:5px;

  margin:.5rem;
  padding:.1rem 0;

  @media ${breakpoints.max.mobileL}{
    min-width:7.5rem;
  }
`;

export const DescriptionContainer = styled.div`
  display:grid;
  place-items:center;
`;

export const Description = styled.div`
  border-radius: 5px;
  font-family:${fonts.lusitana};
  font-weight: normal;
  font-size: 1.2rem;

  color:white;

  @media ${breakpoints.max.mobileL}{
    font-size: 1rem;
  }
`;

export const Icon = styled.div`
  svg{
    width: 1.5rem;
    height: 1.5rem;
    fill:${colors.primary.white};

    @media ${breakpoints.max.mobileL}{
      font-size:28px;
      width: 1.2rem;
      height: 1.2rem;
    }
  }
`;
export const IconContainer = styled.div`
  display:grid;
  place-items:center;
`;
